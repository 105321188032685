<template>
    <Config :siteId="siteId"/>
</template>

<script>
import Config from '@/components/cms/site/emailConfig'
export default {
  name:'email',
  components: { Config},
  data() {
    return {
      siteId:'0',
    }
  }
}
</script>

<style scoped>
</style>
