import request from '@/utils/request'

export function get(data) {
  return request({
    url: 'community/crm/email',
    method: 'get',
    params: data,
  })
}

export function update(data) {
  return request({
    url: 'community/crm/email',
    data,
    method: 'put'
  })
}


export function configEmail(data) {
  return request({
    url: 'community/crm/configEmail',
    data,
    method: 'post'
  })
}

export function send(data) {
  return request({
    url: 'community/crm/email',
    data,
    method: 'post'
  })
}

export function del(id) {
  return request({
    url: '/community/crm/configEmail/' + id,
    method: 'delete'
  })
}
